import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _excluded = ["lazyLoad", "productItem", "visibleActionButton", "onProductClick", "onAddToCart", "onProductSeen", "displayAdultBlur"];

var _templateObject, _templateObject2;

import React, { useCallback, useContext } from "react";
import { AppExperimentContext, SOURCE_TYPES_EMARSYS } from "@catchoftheday/analytics";
import { AddToCartButton } from "@catchoftheday/cart-components";
import { Box, Flex, ImageResponsive, Link, LinkButton } from "@catchoftheday/cg-components";
import { CartContext, CartContextDocument } from "@catchoftheday/common-vendors";
import { CustomerContext } from "@catchoftheday/customer";
import { isActiveOnePassMember, UpsellButton } from "@catchoftheday/onepass";
import { triggerOnePassFreeShippingDrawer } from "@catchoftheday/p13n-integration";
import { styled, theme, themeGet } from "@catchoftheday/theme";
import { addTrailingSlash, centsToDollars, getEffectiveUrl, resizeImage, useIsInViewport } from "@catchoftheday/utilities";
import { ProductPrice } from "../ProductPrice";
import { SalesRulePromotionTag } from "../SalesRulePromotionTag";
import { SponsoredLabel } from "../SponsoredLabel";
import { UnpaidFreeShippingCTA } from "../UnpaidFreeShippingCTA";
import { ProductCardAdultBlur } from "./ProductCardAdultBlur/ProductCardAdultBlur";
import { ProductCardTags } from "./ProductCardTags/ProductCardTags";
import { AvailabilityTags } from "./AvailabilityTags";
import { ProductCardBrand } from "./ProductCardBrand";
import { ProductCardRating } from "./ProductCardRating";
import { ProductCardTitle } from "./ProductCardTitle";
import { jsx as ___EmotionJSX } from "@emotion/react";
var PRODUCT_IMAGE_ASPECT_RATIO = 81.818;
var ProductCardContainer = styled(Flex)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  & div#atcbtn {\n    transition: bottom 0.2s ease-out;\n    position: absolute;\n    bottom: ", ";\n    left: 0;\n    right: 0;\n    background: rgba(255, 255, 255, 0.8);\n    z-index: 5;\n  }\n\n  ", "\n"])), function (props) {
  return !props.visibleActionButton ? "-120px" : 0;
}, function (props) {
  return !props.hasAdultBlur && "\n  @media only screen and (min-width: ".concat(themeGet("breakpoints.lg")(props), ") {\n    &:hover {\n      & div#atcbtn {\n        bottom: 0;\n      }\n      > div {\n        border: 1px solid ").concat(themeGet("colors.secondary")(props), ";\n      }\n    }\n  }");
});
var PriceLink = styled(Link)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  &:hover,\n  &:focus {\n    text-decoration: none;\n  }\n"])));
export var ProductCard = function ProductCard(_ref) {
  var lazyLoad = _ref.lazyLoad,
      productItem = _ref.productItem,
      visibleActionButton = _ref.visibleActionButton,
      onProductClick = _ref.onProductClick,
      onAddToCart = _ref.onAddToCart,
      onProductSeen = _ref.onProductSeen,
      displayAdultBlur = _ref.displayAdultBlur,
      flexProps = _objectWithoutProperties(_ref, _excluded);

  var attributeName = productItem.attributeName,
      averageRating = productItem.averageRating,
      brand = productItem.brand,
      clubCatchEligible = productItem.clubCatchEligible,
      freeShippingAvailable = productItem.freeShippingAvailable,
      id = productItem.id,
      offerId = productItem.offerId,
      ratingCount = productItem.ratingCount,
      sourceId = productItem.sourceId,
      sourcePosition = productItem.sourcePosition,
      sourceType = productItem.sourceType,
      title = productItem.title,
      imageLocation = productItem.imageLocation,
      priceTag = productItem.priceTag,
      badges = productItem.badges,
      clubCatchExclusive = productItem.clubCatchExclusive,
      sellerId = productItem.sellerId,
      adult = productItem.adult,
      sellPrice = productItem.sellPrice,
      trackEventToken = productItem.trackEventToken,
      isSponsored = productItem.isSponsored,
      hideSponsoredLabel = productItem.hideSponsoredLabel,
      productUrl = productItem.url,
      selectedOfferSalesRuleOffers = productItem.selectedOfferSalesRuleOffers,
      variantId = productItem.variantId,
      linkToOffer = productItem.linkToOffer;
  var url = addTrailingSlash("".concat(getEffectiveUrl(productItem)).concat(linkToOffer ? "&offer_id=".concat(offerId) : ""));

  var _useContext = useContext(CustomerContext),
      customerData = _useContext.data;

  var _useContext2 = useContext(CartContext),
      cartData = _useContext2.data;

  var _useContext3 = useContext(AppExperimentContext),
      getExperiments = _useContext3.getExperiments;

  var _getExperiments = getExperiments(["promotion_visibility_web_aug_24"]),
      _getExperiments$exper = _slicedToArray(_getExperiments.experiments, 1),
      promotionVisibilityExperiment = _getExperiments$exper[0],
      promotionVisibilityExperimentLoading = _getExperiments.loading;

  var isPromotionVisibilityExperiment = !promotionVisibilityExperimentLoading && promotionVisibilityExperiment && promotionVisibilityExperiment.bucket > 0;
  var displaySalesRuleTag = isPromotionVisibilityExperiment && selectedOfferSalesRuleOffers && selectedOfferSalesRuleOffers.length > 0;

  var _useIsInViewport = useIsInViewport({
    shouldTrackVisibility: isSponsored,
    onComponentSeen: onProductSeen
  }),
      ref = _useIsInViewport.ref;

  var emarsysAttributionTag = typeof sourceType === "number" && SOURCE_TYPES_EMARSYS.includes(sourceType) ? id : undefined;
  var handleAddToCartClick = useCallback(function () {
    var orderSummary = cartData.orderSummary;
    var itemSummary = orderSummary.itemSummary,
        totalPrice = orderSummary.totalPrice;
    var items = itemSummary.items;

    if (sellPrice && sellPrice <= 7500 && totalPrice.amount <= 7500 && clubCatchEligible && isActiveOnePassMember(customerData)) {
      triggerOnePassFreeShippingDrawer({
        productId: parseInt(id),
        offerId: offerId ? parseInt(offerId) : undefined,
        price: centsToDollars(sellPrice),
        sellerId: sellerId ? parseInt(sellerId) : undefined,
        cartItemProductIds: items.map(function (item) {
          var _item$offer, _item$offer$variant, _item$offer$variant$p;

          return (_item$offer = item.offer) === null || _item$offer === void 0 ? void 0 : (_item$offer$variant = _item$offer.variant) === null || _item$offer$variant === void 0 ? void 0 : (_item$offer$variant$p = _item$offer$variant.product) === null || _item$offer$variant$p === void 0 ? void 0 : _item$offer$variant$p.id;
        }),
        isAdult: adult
      });
    }

    if (onAddToCart) {
      onAddToCart();
    }
  }, [adult, cartData, clubCatchEligible, customerData, id, offerId, onAddToCart, sellPrice, sellerId]);
  return ___EmotionJSX(ProductCardContainer, _extends({
    ref: ref,
    id: "".concat(id),
    px: flexProps.px || flexProps.paddingX || {
      xs: "2px",
      md: "10px"
    },
    textAlign: "center",
    "data-scarabitem": emarsysAttributionTag,
    visibleActionButton: visibleActionButton,
    height: "100%",
    "data-cnstrc-item-id": id,
    "data-cnstrc-item-name": title,
    "data-cnstrc-item-price": priceTag && centsToDollars(priceTag.priceLabel.price.amount),
    "data-cnstrc-item-variation-id": variantId,
    hasAdultBlur: displayAdultBlur && adult
  }, flexProps), ___EmotionJSX(Flex, {
    flexDirection: "column",
    width: "100%",
    position: "relative",
    overflow: "hidden",
    border: "1px solid ".concat(theme.colors.ui.borderColor),
    backgroundColor: "white",
    fontWeight: "light",
    "data-ts": "product-card"
  }, displayAdultBlur && adult && ___EmotionJSX(ProductCardAdultBlur, null), ___EmotionJSX(Box, {
    display: "block",
    position: "relative",
    overflow: "hidden",
    mb: 0
  }, ___EmotionJSX(Link, {
    href: url,
    title: "View ".concat(title),
    hoverColor: "currentColor",
    "data-product-id": id,
    "data-ts": "product-card-link",
    onClick: onProductClick
  }, ___EmotionJSX(ImageResponsive, {
    alt: title,
    lazyLoad: lazyLoad,
    sourceWidths: [100, 200, 300, 400, 500],
    src: resizeImage(imageLocation),
    ratio: PRODUCT_IMAGE_ASPECT_RATIO
  }), badges && ___EmotionJSX(AvailabilityTags, {
    badges: badges
  })), !visibleActionButton && offerId && ___EmotionJSX(Box, {
    padding: "10px",
    id: "atcbtn"
  }, attributeName ? ___EmotionJSX(LinkButton, {
    block: true,
    sizeVariant: "sm",
    href: url,
    title: attributeName,
    variant: "secondary",
    outline: true,
    fontSize: "xs"
  }, "Select ", attributeName) : clubCatchExclusive && !isActiveOnePassMember(customerData) ? ___EmotionJSX(UpsellButton, {
    fontSize: "xs",
    block: true
  }) : ___EmotionJSX(AddToCartButton, {
    variant: "primary",
    block: true,
    color: "",
    p: "",
    textTransform: "uppercase",
    fontSize: "xs",
    underlineOnHover: false,
    imageUrl: imageLocation,
    offerId: offerId,
    sourceId: sourceId,
    sourcePosition: sourcePosition,
    sourceType: sourceType,
    title: title,
    quantity: 1,
    searchTrackingToken: trackEventToken,
    awaitRefetchQueries: true,
    refetchQueries: [{
      query: CartContextDocument
    }],
    onClick: handleAddToCartClick,
    "data-cnstrc-btn": "add_to_cart"
  }, "Add to Cart"))), ___EmotionJSX(Flex, {
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    py: "6px"
  }, ___EmotionJSX(Box, null, isSponsored && !hideSponsoredLabel && ___EmotionJSX(SponsoredLabel, {
    py: "2px"
  }), ___EmotionJSX(ProductCardTitle, {
    url: url,
    productId: id,
    title: title,
    onClick: onProductClick,
    px: 1
  }), brand && ___EmotionJSX(ProductCardBrand, {
    brand: brand
  })), ___EmotionJSX(PriceLink, {
    display: "table",
    px: {
      md: "10px"
    },
    pb: "6px",
    width: "100%",
    textDecoration: "none",
    href: url,
    title: "View ".concat(title),
    "data-product-id": id,
    hoverColor: "inherit",
    onClick: onProductClick
  }, priceTag && ___EmotionJSX(ProductPrice, {
    priceTag: priceTag
  })), displaySalesRuleTag && ___EmotionJSX(Flex, {
    justifyContent: "center",
    alignItems: "center",
    my: 2,
    mx: 2
  }, ___EmotionJSX(SalesRulePromotionTag, {
    title: selectedOfferSalesRuleOffers[0].salesRuleLabel,
    eventSlug: selectedOfferSalesRuleOffers[0].eventSlug,
    containerProps: {
      fontSize: "xs"
    }
  })), ___EmotionJSX(Flex, {
    flexDirection: "row",
    justifyContent: "space-around",
    px: "2px",
    alignItems: "center",
    minHeight: "45px"
  }, (clubCatchEligible || freeShippingAvailable) && ___EmotionJSX(ProductCardTags, {
    onePassEligible: clubCatchEligible,
    freeShippingAvailable: freeShippingAvailable
  }), !!averageRating && averageRating >= 1 && ratingCount > 0 && ___EmotionJSX(ProductCardRating, {
    url: url,
    productId: id,
    rating: averageRating,
    ratingCount: ratingCount
  })), clubCatchEligible && freeShippingAvailable && ___EmotionJSX(UnpaidFreeShippingCTA, {
    upsellDestinationUrl: productUrl,
    containerProps: {
      alignItems: "center",
      justifyContent: "center",
      mb: 3
    }
  }), visibleActionButton && offerId && ___EmotionJSX(Box, {
    id: "atcbtn"
  }, attributeName ? ___EmotionJSX(LinkButton, {
    block: true,
    sizeVariant: "sm",
    href: url,
    title: "Select ".concat(attributeName),
    variant: "secondary",
    outline: true,
    fontSize: "xs"
  }, "Select ", attributeName) : clubCatchExclusive && !isActiveOnePassMember(customerData) ? ___EmotionJSX(UpsellButton, {
    fontSize: "xs",
    block: true
  }) : ___EmotionJSX(AddToCartButton, {
    variant: "tertiary",
    imageUrl: imageLocation,
    offerId: offerId,
    sourceId: sourceId,
    sourcePosition: sourcePosition,
    sourceType: sourceType,
    title: title,
    quantity: 1,
    searchTrackingToken: trackEventToken,
    onClick: onAddToCart
  }, "Add to Cart")))));
};