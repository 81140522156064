import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Flex, IconTickCircle, Link, Truncate } from "@catchoftheday/cg-components";
import { SalesRulePromotionTagDisclaimer } from "../SalesRulePromotionTagDisclaimer";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SalesRulePromotionTag = function SalesRulePromotionTag(_ref) {
  var title = _ref.title,
      eventSlug = _ref.eventSlug,
      eventLink = _ref.eventLink,
      isApplied = _ref.isApplied,
      containerProps = _ref.containerProps;
  var url = eventLink || "/event/".concat(eventSlug);
  return ___EmotionJSX(Flex, _extends({
    color: isApplied ? "brand.tertiary" : "brand.secondaryDark",
    fontSize: "sm",
    fontWeight: "medium",
    py: 1,
    px: isApplied ? 0 : 2,
    borderLeft: isApplied ? 0 : "2px solid",
    borderRadius: 2,
    gap: 1,
    borderLeftColor: "secondary",
    backgroundColor: isApplied ? "" : "secondaryLight",
    alignItems: "center",
    textAlign: "left"
  }, containerProps), eventSlug ? ___EmotionJSX(React.Fragment, null, isApplied && ___EmotionJSX(IconTickCircle, {
    height: 12,
    width: 12
  }), ___EmotionJSX(Truncate, {
    lines: 1,
    fontSize: "inherit",
    fontWeight: "inherit"
  }, ___EmotionJSX(Link, {
    href: url,
    color: "inherit",
    textDecoration: "underline",
    style: {
      cursor: "pointer"
    }
  }, title))) : ___EmotionJSX(Truncate, {
    lines: 1,
    fontSize: "inherit",
    fontWeight: "inherit",
    m: 0
  }, isApplied ? "Saving Applied " : "", title), ___EmotionJSX(Flex, {
    flex: "0 0 auto"
  }, ___EmotionJSX(SalesRulePromotionTagDisclaimer, {
    title: title
  })));
};
export default SalesRulePromotionTag;