import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useCallback, useContext, useMemo, useState } from "react";
import { emitProductGroupImpression, emitProductImpression, emitProductListInteraction, emitRecommenderImpression, emitRecommenderRequest, SOURCE_TYPE_COPURCHASED_PRODUCTS_RECOMMENDER } from "@catchoftheday/analytics";
import { AddItemsToCartButton } from "@catchoftheday/cart-components";
import { Alert, Box, Flex, Heading, Image, Link, List, ListItem, LoadingSpinner, Span, Text } from "@catchoftheday/cg-components";
import { CustomerContext } from "@catchoftheday/customer";
import { useBreakpoint } from "@catchoftheday/theme";
import { centsToDollars, formatPrice, getEncodedUrl, resizeImage, useIsInViewport } from "@catchoftheday/utilities";
import { useCoPurchasedProductsQuery } from "@personalisation/generated/graphql";
import { jsx as ___EmotionJSX } from "@emotion/react";
var sourceType = SOURCE_TYPE_COPURCHASED_PRODUCTS_RECOMMENDER;
var sourceId = "product_page_recommender_bi_copurchase_product";

var CoPurchasedRecommenderItem = function CoPurchasedRecommenderItem(_ref) {
  var currentlyViewing = _ref.currentlyViewing,
      url = _ref.url,
      assets = _ref.assets,
      title = _ref.title,
      offer = _ref.offer,
      onClick = _ref.onClick;
  return ___EmotionJSX(ListItem, {
    mb: "16px"
  }, ___EmotionJSX(Flex, {
    alignItems: "flex-start"
  }, ___EmotionJSX(Box, {
    border: "solid 1px",
    borderColor: "ui.borderColor"
  }, currentlyViewing ? ___EmotionJSX(Image, {
    src: resizeImage(assets.hero.url, "_w200h164"),
    alt: assets.hero.altText || title,
    width: "100px",
    height: "82px"
  }) : ___EmotionJSX(Link, {
    href: url,
    onClick: onClick
  }, ___EmotionJSX(Image, {
    src: resizeImage(assets.hero.url, "_w200h164"),
    alt: assets.hero.altText || title,
    width: "100px",
    height: "82px"
  }))), ___EmotionJSX(Box, {
    pl: "16px"
  }, currentlyViewing ? ___EmotionJSX(Span, {
    backgroundColor: "ui.borderColor",
    fontSize: "xs",
    fontWeight: "normal",
    px: "8px",
    py: "3px",
    borderRadius: "3px"
  }, "Currently viewing") : ___EmotionJSX(Link, {
    fontWeight: "light",
    href: url,
    color: "text",
    onClick: onClick,
    fontSize: "sm"
  }, title), ___EmotionJSX(Text, {
    fontWeight: "normal",
    fontSize: "sm",
    mt: "12px"
  }, formatPrice(offer.sellPrice.amount)))));
};

export var CoPurchasedRecommender = function CoPurchasedRecommender(_ref2) {
  var _customer$club;

  var product = _ref2.product,
      eventId = _ref2.eventId,
      adult = _ref2.adult;

  var _useState = useState(),
      error = _useState[0],
      setError = _useState[1];

  var _useState2 = useState(false),
      loading = _useState2[0],
      setLoading = _useState2[1];

  var _useContext = useContext(CustomerContext),
      customer = _useContext.data,
      customerLoading = _useContext.loading;

  var _useBreakpoint = useBreakpoint({
    minusOnePx: true
  }),
      isMobile = _useBreakpoint.isMobile;

  var isClubMember = (customer === null || customer === void 0 ? void 0 : (_customer$club = customer.club) === null || _customer$club === void 0 ? void 0 : _customer$club.active) || false;
  var productId = product.id;
  var fromProduct = useMemo(function () {
    return {
      from_product_ids: [Number(productId)],
      from_product_offer_id: Number(product.offer.id),
      from_product_displayed_price: centsToDollars(product.offer.sellPrice.amount)
    };
  }, [productId, product.offer]);

  var _useCoPurchasedProduc = useCoPurchasedProductsQuery({
    variables: {
      productId: productId,
      includeClubProducts: isClubMember,
      adult: adult
    },
    ssr: false,
    skip: customerLoading,
    onCompleted: function onCompleted(_ref3) {
      var _ref3$coPurchasedProd = _ref3.coPurchasedProducts.metaData,
          preHydrationCount = _ref3$coPurchasedProd.preHydrationCount,
          postHydrationCount = _ref3$coPurchasedProd.postHydrationCount,
          hydrationErrorCount = _ref3$coPurchasedProd.hydrationErrorCount;
      emitRecommenderRequest(_objectSpread({
        source_type: SOURCE_TYPE_COPURCHASED_PRODUCTS_RECOMMENDER,
        pre_hydration_product_count: preHydrationCount,
        post_hydration_product_count: postHydrationCount,
        hydration_error_count: hydrationErrorCount
      }, fromProduct));
    }
  }),
      coPurchasedRecommenderLoading = _useCoPurchasedProduc.loading,
      coPurchasedRecommenderError = _useCoPurchasedProduc.error,
      coPurchasedRecommenderData = _useCoPurchasedProduc.data;

  var recommendedProducts = coPurchasedRecommenderData === null || coPurchasedRecommenderData === void 0 ? void 0 : coPurchasedRecommenderData.coPurchasedProducts.products.map(function (product) {
    if (product.bestOffer) {
      return _objectSpread(_objectSpread({}, product), {}, {
        offer: product.bestOffer
      });
    }
  });
  var productsToBeAdded = [_objectSpread({}, product)].concat(recommendedProducts || []);
  var totalProducts = productsToBeAdded.length;
  var totalPrice = productsToBeAdded.reduce(function (total, product) {
    return total + product.offer.sellPrice.amount;
  }, 0);
  var addToCartItems = productsToBeAdded.map(function (product, index) {
    return {
      title: product.title,
      offerId: product.offer.id,
      quantity: 1,
      imageUrl: product.assets.hero.url,
      sourceId: sourceId,
      sourceType: sourceType,
      sourcePosition: index + 1,
      eventId: eventId ? Number(eventId) : undefined
    };
  });
  var trackListInteraction = useCallback(function (_ref4) {
    var productItem = _ref4.product,
        position = _ref4.position,
        interaction = _ref4.interaction;
    emitProductListInteraction(_objectSpread({
      interaction: interaction,
      product_id: Number(productItem.id),
      offer_id: Number(productItem.offer.id),
      source_position: position,
      source_type: sourceType,
      source_id: sourceId
    }, fromProduct));
  }, [fromProduct]);
  var trackAddToCartInteractions = useCallback(function () {
    productsToBeAdded.forEach(function (product, index) {
      trackListInteraction({
        product: product,
        position: index + 1,
        interaction: "addToCart"
      });
    });
  }, [productsToBeAdded, trackListInteraction]);
  var trackImpressions = useCallback(function () {
    if (!recommendedProducts) {
      return;
    }

    emitProductGroupImpression({
      source_type: sourceType,
      product_count: productsToBeAdded.length,
      displayed_group_price: centsToDollars(totalPrice)
    });
    productsToBeAdded.forEach(function (productItem, index) {
      emitProductImpression(_objectSpread({
        offer_id: Number(productItem.offer.id),
        source_id: sourceId,
        source_position: index + 1,
        source_type: sourceType,
        product_id: Number(productItem.id),
        product_title: productItem.title,
        displayed_price: centsToDollars(productItem.offer.sellPrice.amount)
      }, fromProduct));
    });
    emitRecommenderImpression({
      source_type: sourceType,
      recommended_product_count: recommendedProducts.length,
      from_product_ids: [Number(productId)],
      from_product_offer_id: Number(product.offer.id),
      from_product_displayed_price: centsToDollars(product.offer.sellPrice.amount)
    });
  }, [recommendedProducts, product, productId, productsToBeAdded, totalPrice, fromProduct]);

  var _useIsInViewport = useIsInViewport({
    shouldTrackVisibility: !!recommendedProducts,
    threshold: isMobile ? 0.75 : 1,
    onComponentSeen: trackImpressions
  }),
      ref = _useIsInViewport.ref;

  if (coPurchasedRecommenderLoading || coPurchasedRecommenderError || productsToBeAdded.length <= 1 || !product.offer) {
    return null;
  }

  return ___EmotionJSX(Box, {
    ref: ref,
    border: "solid 1px",
    borderColor: "ui.borderColor",
    p: "16px",
    mb: "16px",
    backgroundColor: "white",
    position: "relative"
  }, loading && ___EmotionJSX(LoadingSpinner, {
    hasOverlay: true
  }), ___EmotionJSX(Heading.h4, {
    mb: "16px",
    mt: "0",
    textAlign: {
      xs: "center",
      md: "left"
    }
  }, "Frequently bought together"), ___EmotionJSX(List, null, productsToBeAdded.map(function (product, index) {
    return ___EmotionJSX(CoPurchasedRecommenderItem, {
      key: product.id,
      currentlyViewing: index === 0,
      url: getEncodedUrl("/product/".concat(product.id), {
        sp: (index + 1).toString(),
        st: "".concat(sourceType),
        sid: sourceId,
        pid: product.id,
        oid: product.offer.id
      }),
      assets: product.assets,
      title: product.title,
      offer: product.offer,
      onClick: function onClick() {
        return trackListInteraction({
          product: product,
          position: index + 1,
          interaction: "click"
        });
      }
    });
  })), error && ___EmotionJSX(Alert, {
    type: "inline",
    variant: "error",
    showCloseButton: false,
    wrapperProps: {
      mb: "10px"
    }
  }, ___EmotionJSX(Flex, {
    flexWrap: "wrap"
  }, ___EmotionJSX(Box, {
    my: "2px",
    mr: "4px",
    fontSize: "inherit",
    fontWeight: "normal"
  }, "Something went wrong. Please try again."))), product.offer.purchasableQuantity > 0 && ___EmotionJSX(Flex, {
    justifyContent: "space-between",
    alignItems: "center"
  }, ___EmotionJSX(Text, {
    fontSize: "sm",
    mt: "12px"
  }, "Total:", " ", ___EmotionJSX(Span, {
    fontSize: "base",
    fontWeight: "normal"
  }, formatPrice(totalPrice))), ___EmotionJSX(AddItemsToCartButton, {
    items: addToCartItems,
    setError: setError,
    setLoading: setLoading,
    onClick: trackAddToCartInteractions,
    variant: "secondary",
    outline: true,
    size: "sm"
  }, "Add ", totalProducts, " items to cart")));
};