import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

// @ts-ignore

/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
var defaultOptions = {};
export var SellerPriceTagDataFragmentDoc = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    fragment SellerPriceTagData on PriceTag {\n  __typename\n  priceDiscount {\n    label\n    percentageLabel\n    endDate\n  }\n  topLabel\n  priceLabel {\n    wasPrice {\n      amount\n    }\n    price {\n      amount\n    }\n    saving {\n      label\n      savingAmount {\n        amount\n      }\n    }\n  }\n  clubSellPrice {\n    amount\n  }\n  clubExclusive\n  enabled\n  retailPriceDisclaimer {\n    description\n  }\n}\n    "])));
export var SellerProductCardDataFragmentDoc = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    fragment SellerProductCardData on Product {\n  __typename\n  id\n  title\n  ratingsSummary {\n    numberOfRatings\n    averageRating\n  }\n  assets {\n    hero {\n      url\n      altText\n    }\n  }\n  brand {\n    name\n    slug\n  }\n  priceTag {\n    ...SellerPriceTagData\n  }\n  selectedOffer {\n    id\n    clubExclusive\n    clubFreeShipping\n    freeShipping\n    sellPrice {\n      amount\n    }\n    seller {\n      id\n      name\n    }\n    badges\n    priceTag {\n      ...SellerPriceTagData\n    }\n    variant {\n      id\n    }\n    salesRuleOffers {\n      id\n      label\n      eventId\n      eventSlug\n    }\n  }\n  options {\n    name\n  }\n  sellable\n  slug\n  adult\n}\n    ", ""])), SellerPriceTagDataFragmentDoc);
export var RetailMediaBannerDocument = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    query RetailMediaBanner($inventoryId: String!, $snowplowSessionId: String, $customerUUID: String, $platform: Platform!, $searchQuery: String, $offerId: String, $category: String, $pageId: String) {\n  retailMediaBanner(\n    input: {inventoryId: $inventoryId, snowplowSessionId: $snowplowSessionId, customerUUID: $customerUUID, platform: $platform, searchQuery: $searchQuery, offerId: $offerId, category: $category, pageId: $pageId}\n  ) {\n    banner {\n      id\n      path\n      title\n      image {\n        url\n        width\n        height\n        altText\n      }\n      platforms\n    }\n    tracking {\n      clickTrackingUrl\n      impressionTrackingUrl\n    }\n  }\n}\n    "])));
/**
 * __useRetailMediaBannerQuery__
 *
 * To run a query within a React component, call `useRetailMediaBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetailMediaBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetailMediaBannerQuery({
 *   variables: {
 *      inventoryId: // value for 'inventoryId'
 *      snowplowSessionId: // value for 'snowplowSessionId'
 *      customerUUID: // value for 'customerUUID'
 *      platform: // value for 'platform'
 *      searchQuery: // value for 'searchQuery'
 *      offerId: // value for 'offerId'
 *      category: // value for 'category'
 *      pageId: // value for 'pageId'
 *   },
 * });
 */

export function useRetailMediaBannerQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(RetailMediaBannerDocument, options);
}
export function useRetailMediaBannerLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(RetailMediaBannerDocument, options);
}
export function useRetailMediaBannerSuspenseQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(RetailMediaBannerDocument, options);
}
export var RetailMediaBannerOffersDocument = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    query RetailMediaBannerOffers($requestId: String!, $limit: Int, $offset: Int) {\n  retailMediaBannerOffers(\n    input: {requestId: $requestId, limit: $limit, offset: $offset}\n  ) {\n    result {\n      products {\n        ...SellerProductCardData\n      }\n      tracking {\n        id\n        clickTrackingUrl\n        impressionTrackingUrl\n        productId\n      }\n    }\n    totalCount\n    sellerSlug\n    sellerName\n  }\n}\n    ", ""])), SellerProductCardDataFragmentDoc);
/**
 * __useRetailMediaBannerOffersQuery__
 *
 * To run a query within a React component, call `useRetailMediaBannerOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetailMediaBannerOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetailMediaBannerOffersQuery({
 *   variables: {
 *      requestId: // value for 'requestId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */

export function useRetailMediaBannerOffersQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(RetailMediaBannerOffersDocument, options);
}
export function useRetailMediaBannerOffersLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(RetailMediaBannerOffersDocument, options);
}
export function useRetailMediaBannerOffersSuspenseQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(RetailMediaBannerOffersDocument, options);
}