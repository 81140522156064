import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import React, { useEffect, useState } from "react";
import { Box, Container } from "@catchoftheday/cg-components";
import { cookies } from "@catchoftheday/utilities";
import { SalesRulePromotionNotice } from "../SalesRulePromotionNotice";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var LAST_SHOWN_CART_PROMOTION_COOKIE = "LSCP";
export var SalesRulePromotionNoticeContainer = function SalesRulePromotionNoticeContainer(_ref) {
  var _lastShownPromoData$e;

  var salesPromotions = _ref.salesPromotions,
      containerProps = _ref.containerProps;
  var lastShownCartPromotion = cookies.get(LAST_SHOWN_CART_PROMOTION_COOKIE);

  var checkNotice = function checkNotice(salesPromotions, cpCookie) {
    var _ref2 = (cpCookie === null || cpCookie === void 0 ? void 0 : cpCookie.split(".")) || ["", ""],
        _ref3 = _slicedToArray(_ref2, 2),
        scpStr = _ref3[0],
        lscp = _ref3[1];

    var selectedSalesRulePromotion = salesPromotions.find(function (promotion) {
      return promotion.isApplied === false;
    });
    var isShowNotice = parseInt(scpStr || "0");
    var newLastShownCartPromotion = lscp;

    if (!selectedSalesRulePromotion) {
      isShowNotice = 0;
      cookies.remove(LAST_SHOWN_CART_PROMOTION_COOKIE);
    } else {
      newLastShownCartPromotion = selectedSalesRulePromotion.id;

      if (selectedSalesRulePromotion.id !== lscp) {
        isShowNotice = 1;
        cookies.set(LAST_SHOWN_CART_PROMOTION_COOKIE, "".concat(isShowNotice, ".").concat(newLastShownCartPromotion));
      }
    }

    var slcpDetail = salesPromotions.find(function (promotion) {
      return promotion.id === newLastShownCartPromotion;
    });
    return [isShowNotice, slcpDetail];
  };

  var _useState = useState(false),
      isShown = _useState[0],
      setIsShown = _useState[1];

  var _useState2 = useState(false),
      mounted = _useState2[0],
      setMounted = _useState2[1];

  var _useState3 = useState(),
      lastShownPromoData = _useState3[0],
      setLastShownPromoData = _useState3[1];

  useEffect(function () {
    if (!mounted && salesPromotions) {
      var _checkNotice = checkNotice(salesPromotions, lastShownCartPromotion),
          _checkNotice2 = _slicedToArray(_checkNotice, 2),
          showPromoNotice = _checkNotice2[0],
          lastPromoData = _checkNotice2[1];

      setLastShownPromoData(lastPromoData);
      setIsShown(Boolean(showPromoNotice));
      setMounted(true);
    }
  }, [salesPromotions]);
  return isShown && lastShownPromoData ? ___EmotionJSX(Box, _extends({
    "aria-label": "sales promotion cart notice",
    backgroundColor: "textSecondary",
    px: {
      xs: 2,
      lg: 7
    }
  }, containerProps), ___EmotionJSX(Container, {
    height: "100%",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: "1320px"
  }, ___EmotionJSX(SalesRulePromotionNotice, {
    message: lastShownPromoData.upsellLabel,
    linkText: lastShownPromoData.upsellLabelSpan,
    eventSlug: (_lastShownPromoData$e = lastShownPromoData.event) === null || _lastShownPromoData$e === void 0 ? void 0 : _lastShownPromoData$e.slug,
    showCloseButton: true,
    hideNotice: function hideNotice() {
      setIsShown(false);
      cookies.set(LAST_SHOWN_CART_PROMOTION_COOKIE, "0.".concat(lastShownPromoData.id));
    }
  }))) : null;
};