export var convertProductCard = function convertProductCard(productItem, offer) {
  var _productItem$options$, _offer$sellPrice, _offer$variant;

  return {
    id: productItem.id,
    adult: productItem.adult,
    attributeName: (_productItem$options$ = productItem.options[0]) === null || _productItem$options$ === void 0 ? void 0 : _productItem$options$.name,
    averageRating: productItem.ratingsSummary.averageRating,
    brand: productItem.brand,
    clubCatchEligible: offer === null || offer === void 0 ? void 0 : offer.clubFreeShipping,
    clubCatchExclusive: offer === null || offer === void 0 ? void 0 : offer.clubExclusive,
    freeShippingAvailable: offer === null || offer === void 0 ? void 0 : offer.freeShipping,
    imageLocation: productItem.assets.hero.url,
    isPersonalisedProduct: productItem.options.length > 0,
    offerId: offer === null || offer === void 0 ? void 0 : offer.id,
    ratingCount: productItem.ratingsSummary.numberOfRatings,
    sellable: productItem.sellable,
    sellerId: offer === null || offer === void 0 ? void 0 : offer.seller.id,
    title: productItem.title,
    url: "/product/".concat(productItem.slug),
    priceTag: (offer === null || offer === void 0 ? void 0 : offer.priceTag) || productItem.priceTag,
    badges: offer === null || offer === void 0 ? void 0 : offer.badges,
    sellPrice: offer === null || offer === void 0 ? void 0 : (_offer$sellPrice = offer.sellPrice) === null || _offer$sellPrice === void 0 ? void 0 : _offer$sellPrice.amount,
    isSponsored: productItem.isSponsored,
    hideSponsoredLabel: productItem.hideSponsoredLabel,
    sourceId: productItem.sourceId,
    sourceType: productItem.sourceType,
    selectedOfferSalesRuleOffers: mapSalesRuleOffers((offer === null || offer === void 0 ? void 0 : offer.salesRuleOffers) || []),
    variantId: offer === null || offer === void 0 ? void 0 : (_offer$variant = offer.variant) === null || _offer$variant === void 0 ? void 0 : _offer$variant.id,
    linkToOffer: productItem.linkToOffer
  };
};

var mapSalesRuleOffers = function mapSalesRuleOffers(salesRuleOffers) {
  return salesRuleOffers.map(function (_ref) {
    var id = _ref.id,
        label = _ref.label,
        eventId = _ref.eventId,
        eventSlug = _ref.eventSlug;
    return {
      salesRuleId: id,
      salesRuleLabel: label,
      eventId: eventId,
      eventSlug: eventSlug
    };
  });
};