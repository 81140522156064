import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

// @ts-ignore

/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
var defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */

export var NoticeDataFragmentDoc = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    fragment NoticeData on CartNotice {\n  message {\n    template\n    placeholders {\n      id\n      text\n      url\n    }\n    default\n  }\n  type\n  advisory\n}\n    "])));
export var ParcelItemDataFragmentDoc = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    fragment ParcelItemData on CartParcelItem {\n  id\n  offer {\n    id\n    purchasableQuantity\n    clubFreeShipping\n    freeShipping\n    originPrice {\n      amount\n    }\n    sellPrice {\n      amount\n    }\n    variant {\n      inWishlist\n      product {\n        id\n        assets {\n          hero {\n            id\n            url\n            altText\n          }\n        }\n      }\n      variantOptionValues {\n        id\n        name\n        option {\n          name\n        }\n      }\n    }\n  }\n  freeShipping\n  uri\n  quantity\n  totalPrice {\n    amount\n  }\n  addedByCoupon\n  title\n  personalisedValues {\n    label\n    value\n  }\n  notices {\n    ...NoticeData\n  }\n  metadata {\n    sourcePosition\n    sourceType\n    eventId\n  }\n}\n    ", ""])), NoticeDataFragmentDoc);
export var DeliveryEstimateDataFragmentDoc = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    fragment DeliveryEstimateData on DeliveryEstimate {\n  label\n  labelDescription\n  labelType\n  shortLabel\n  minRange\n  maxRange\n  timeStamp\n  confidence\n  deliveryType\n}\n    "])));
export var ParcelsDataFragmentDoc = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    fragment ParcelsData on CartParcel {\n  id\n  sellerName\n  clubUpsellEligible\n  isFBC\n  isShippingMemberDiscounted\n  marketplaceSeller\n  items {\n    ...ParcelItemData\n  }\n  dispatchNotice {\n    message\n    type\n  }\n  deliveryEstimate {\n    ...DeliveryEstimateData\n  }\n  selectedShippingOption {\n    id\n    availableCnCOptions\n    price {\n      amount\n      currency\n    }\n    type\n    locationType\n    title\n    clubFreeShipping\n    availableCnCOptions\n    deliveryShortLabel\n  }\n  shippingOptions {\n    id\n    availableCnCOptions\n    type\n    locationType\n    title\n    price {\n      amount\n      currency\n    }\n    attentionTag\n    clubFreeShipping\n    availableCnCOptions\n    deliveryShortLabel\n  }\n  subtotalPrice {\n    amount\n  }\n  subGroups {\n    id\n    items {\n      ...ParcelItemData\n    }\n    deliveryEstimate {\n      ...DeliveryEstimateData\n    }\n  }\n}\n    ", "\n", ""])), ParcelItemDataFragmentDoc, DeliveryEstimateDataFragmentDoc);
export var OrderSummaryDataFragmentDoc = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    fragment OrderSummaryData on CartOrderSummary {\n  id\n  totalSaving {\n    amount\n  }\n  itemSummary {\n    total {\n      amount\n    }\n    items {\n      id\n      uri\n      quantity\n      totalPrice {\n        amount\n      }\n      addedByCoupon\n      title\n      offer {\n        id\n        purchasableQuantity\n        clubFreeShipping\n        originPrice {\n          amount\n        }\n        sellPrice {\n          amount\n        }\n        variant {\n          inWishlist\n          product {\n            id\n            assets {\n              hero {\n                id\n                url\n                altText\n              }\n            }\n          }\n          variantOptionValues {\n            id\n            name\n            option {\n              name\n            }\n          }\n        }\n      }\n      metadata {\n        eventId\n        sourcePosition\n        sourceType\n      }\n    }\n  }\n  delivery {\n    total {\n      amount\n    }\n    deliveryType\n  }\n  donation {\n    total {\n      amount\n    }\n    organisationName\n    image {\n      url\n      altText\n    }\n    body\n    header\n    orderTotalBefore {\n      amount\n      currency\n    }\n    orderTotalAfter {\n      amount\n      currency\n    }\n  }\n  giftCards {\n    code\n    isOnePassReward\n    appliedBalance {\n      amount\n    }\n    remainingBalance {\n      amount\n    }\n  }\n  discounts {\n    discount {\n      amount\n    }\n    label\n    deletable\n  }\n  storeCredit {\n    applied {\n      amount\n    }\n    remaining {\n      amount\n    }\n  }\n  ageConfirmationRequired\n  subtotalPrice {\n    amount\n  }\n  totalPrice {\n    amount\n    currency\n  }\n}\n    "])));
export var SalesRulePromotionDataFragmentDoc = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n    fragment SalesRulePromotionData on SalesRulePromotion {\n  id\n  label\n  upsellLabel\n  upsellLabelSpan\n  isApplied\n  event {\n    id\n    slug\n  }\n  cartItemIds\n}\n    "])));
export var CartDataFragmentDoc = gql(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n    fragment CartData on Cart {\n  id\n  canProceedToCheckout\n  parcels {\n    ...ParcelsData\n  }\n  orderSummary {\n    ...OrderSummaryData\n  }\n  notices {\n    ...NoticeData\n  }\n  itemsQuantitySum\n  userCoupon {\n    coupon {\n      code\n    }\n    notices {\n      ...NoticeData\n    }\n  }\n  shippingLocation {\n    city\n    stateCode\n    postcode\n  }\n  clubSubscription {\n    id\n    offerUpsell\n    options {\n      id\n      title\n      subtitle\n      freeTrial\n      interval\n      nextBillingDate\n      price {\n        amount\n      }\n      clubImage {\n        url\n      }\n    }\n    selectedOption {\n      id\n      title\n      subtitle\n      freeTrial\n      interval\n      nextBillingDate\n      price {\n        amount\n      }\n      clubImage {\n        url\n      }\n    }\n  }\n  salesRulePromotions {\n    ...SalesRulePromotionData\n  }\n}\n    ", "\n", "\n", "\n", ""])), ParcelsDataFragmentDoc, OrderSummaryDataFragmentDoc, NoticeDataFragmentDoc, SalesRulePromotionDataFragmentDoc);
export var AddItemsToCartDocument = gql(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n    mutation AddItemsToCart($itemsToBeAdded: AddItemsToCartInput!) {\n  addItemsToCart(input: $itemsToBeAdded) {\n    cart {\n      ...CartData\n    }\n    errors {\n      ... on CartItemError {\n        message\n        offerId\n      }\n    }\n  }\n}\n    ", ""])), CartDataFragmentDoc);

/**
 * __useAddItemsToCartMutation__
 *
 * To run a mutation, you first call `useAddItemsToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddItemsToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addItemsToCartMutation, { data, loading, error }] = useAddItemsToCartMutation({
 *   variables: {
 *      itemsToBeAdded: // value for 'itemsToBeAdded'
 *   },
 * });
 */
export function useAddItemsToCartMutation(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(AddItemsToCartDocument, options);
}
export var AddToCartDocument = gql(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n    mutation AddToCart($offerId: ID!, $eventId: Int, $quantity: Int, $sourcePlatform: SourcePlatformType, $sourceId: String, $sourceType: String, $sourcePosition: Int, $personalisedValues: [PersonalisedValueInput!]) {\n  addToCart(\n    input: {offerId: $offerId, eventId: $eventId, quantity: $quantity, sourcePlatform: $sourcePlatform, sourceId: $sourceId, sourceType: $sourceType, sourcePosition: $sourcePosition, personalisedValues: $personalisedValues}\n  ) {\n    ... on Error {\n      message\n    }\n    ... on Cart {\n      ...CartData\n    }\n  }\n}\n    ", ""])), CartDataFragmentDoc);

/**
 * __useAddToCartMutation__
 *
 * To run a mutation, you first call `useAddToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToCartMutation, { data, loading, error }] = useAddToCartMutation({
 *   variables: {
 *      offerId: // value for 'offerId'
 *      eventId: // value for 'eventId'
 *      quantity: // value for 'quantity'
 *      sourcePlatform: // value for 'sourcePlatform'
 *      sourceId: // value for 'sourceId'
 *      sourceType: // value for 'sourceType'
 *      sourcePosition: // value for 'sourcePosition'
 *      personalisedValues: // value for 'personalisedValues'
 *   },
 * });
 */
export function useAddToCartMutation(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(AddToCartDocument, options);
}
export var CartDocument = gql(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n    query Cart {\n  cart {\n    ...CartData\n  }\n}\n    ", ""])), CartDataFragmentDoc);
/**
 * __useCartQuery__
 *
 * To run a query within a React component, call `useCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartQuery({
 *   variables: {
 *   },
 * });
 */

export function useCartQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(CartDocument, options);
}
export function useCartLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(CartDocument, options);
}
export function useCartSuspenseQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(CartDocument, options);
}
export var MoveItemFromCartToWishlistDocument = gql(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n    mutation MoveItemFromCartToWishlist($id: ID!) {\n  moveItemFromCartToWishlist(input: {id: $id}) {\n    ... on Cart {\n      ...CartData\n    }\n    ... on UserError {\n      message\n    }\n  }\n}\n    ", ""])), CartDataFragmentDoc);

/**
 * __useMoveItemFromCartToWishlistMutation__
 *
 * To run a mutation, you first call `useMoveItemFromCartToWishlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveItemFromCartToWishlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveItemFromCartToWishlistMutation, { data, loading, error }] = useMoveItemFromCartToWishlistMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMoveItemFromCartToWishlistMutation(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(MoveItemFromCartToWishlistDocument, options);
}
export var RemoveItemFromCartDocument = gql(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n    mutation RemoveItemFromCart($itemId: ID, $offerId: ID) {\n  removeItemFromCart(input: {itemId: $itemId, offerId: $offerId}) {\n    ... on Cart {\n      ...CartData\n    }\n    ... on UserError {\n      message\n    }\n  }\n}\n    ", ""])), CartDataFragmentDoc);

/**
 * __useRemoveItemFromCartMutation__
 *
 * To run a mutation, you first call `useRemoveItemFromCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveItemFromCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeItemFromCartMutation, { data, loading, error }] = useRemoveItemFromCartMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useRemoveItemFromCartMutation(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(RemoveItemFromCartDocument, options);
}