import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React from "react";
import { Box, Flex, IconX, Link, Text } from "@catchoftheday/cg-components";
import { styled } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
var CloseIconContainer = styled(Box)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  :focus {\n    outline: 1px solid white;\n  }\n"])));
export var SalesRulePromotionNotice = function SalesRulePromotionNotice(_ref) {
  var message = _ref.message,
      linkText = _ref.linkText,
      _ref$showCloseButton = _ref.showCloseButton,
      showCloseButton = _ref$showCloseButton === void 0 ? false : _ref$showCloseButton,
      eventSlug = _ref.eventSlug,
      hideNotice = _ref.hideNotice,
      containerProps = _ref.containerProps;

  var ProgressCopy = function ProgressCopy() {
    if (eventSlug && message && linkText) {
      var regPattern = new RegExp("".concat(linkText), "i");
      var copyArr = message.split(regPattern);
      return ___EmotionJSX(Text, {
        fontSize: "small",
        color: "white",
        fontWeight: "700",
        my: 0
      }, copyArr[0], ___EmotionJSX(Link, {
        href: "/event/".concat(eventSlug),
        textDecoration: "underline",
        color: "inherit"
      }, linkText), copyArr[1]);
    }

    return ___EmotionJSX(Text, {
      fontSize: "small",
      color: "white",
      m: 0,
      fontWeight: "700"
    }, message);
  };

  return ___EmotionJSX(Flex, _extends({
    "aria-label": "sales promotion cart text",
    py: 2,
    justifyContent: "center",
    alignItems: "center",
    position: "relative"
  }, containerProps), ProgressCopy(), showCloseButton && ___EmotionJSX(CloseIconContainer, {
    role: "button",
    "aria-label": "close button",
    tabIndex: 0,
    position: "absolute",
    right: 0,
    cursor: "pointer",
    color: "white",
    zIndex: 1,
    onClick: hideNotice
  }, ___EmotionJSX(IconX, {
    height: 28,
    width: 28
  })));
};