import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useRef, useState } from "react";
import { emitRecommenderRequest, SOURCE_TYPE_CATCH_PRODUCT_RECOMMENDER } from "@catchoftheday/analytics";
import { Modal } from "@catchoftheday/cg-components";
import { TRIGGER_ONEPASS_FREE_SHIPPING_DRAWER_EVENT } from "@catchoftheday/p13n-integration";
import { useEventBus } from "@catchoftheday/state";
import { keyframes, minusOnePx, styled, useMedia, useTheme } from "@catchoftheday/theme";
import { useSellerRecommendedProductsQuery } from "@personalisation/generated/graphql";
import { BaseRecommenderCarousel } from "../RecommenderCarousel";
import { getProductTiles, getRecommenderTrackingData, handleRecommenderQueryUpdate, useVisibleRecommenderTiles } from "../utils";
import { ModalContent } from "./ModalContent";
import { jsx as ___EmotionJSX } from "@emotion/react";
var ONE_PASS_POPUP_RECOMMENDER_SOURCE_ID = "OnePass Popup Recommender";
var slide = keyframes(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  from {\n    transform: translateY(150%);\n  }\n\n  to {\n    transform: translateY(0%);\n  }\n"])));
var FreeShippingModal = styled(Modal)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  .ModalContent {\n    position: absolute;\n    bottom: 0;\n    z-index: 5;\n    width: 100%;\n    height: unset;\n    overflow: hidden;\n    animation: ", " 1s ease-in-out;\n    box-shadow: 0 0 5px black;\n    border-radius: 0;\n    border: none;\n    padding: 0;\n  }\n"])), slide);
export var FreeShippingDrawer = function FreeShippingDrawer() {
  var theme = useTheme();

  var _useState = useState(false),
      isOpen = _useState[0],
      setIsOpen = _useState[1];

  var _useState2 = useState(),
      eventPayload = _useState2[0],
      setEventPayload = _useState2[1];

  var fromProduct = {
    from_product_ids: eventPayload !== null && eventPayload !== void 0 && eventPayload.productId ? [Number(eventPayload.productId)] : null,
    from_product_offer_id: (eventPayload === null || eventPayload === void 0 ? void 0 : eventPayload.offerId) || null,
    from_product_displayed_price: (eventPayload === null || eventPayload === void 0 ? void 0 : eventPayload.price) || null
  };
  var requiredVariables = {
    limit: useVisibleRecommenderTiles(),
    offset: 0
  };
  var trackingVariables = useRef(requiredVariables);

  var _useSellerRecommended = useSellerRecommendedProductsQuery({
    skip: !eventPayload || !requiredVariables.limit,
    variables: eventPayload ? _objectSpread({
      productId: eventPayload.productId,
      excludedProductIds: eventPayload.excludedProductIds,
      sellerId: eventPayload.sellerId,
      includeAdultCategory: eventPayload.includeAdultCategory
    }, requiredVariables) : requiredVariables,
    ssr: false,
    notifyOnNetworkStatusChange: true,
    onCompleted: function onCompleted(_ref) {
      var metaData = _ref.sellerRecommendedProducts.metaData;
      emitRecommenderRequest(_objectSpread({
        source_type: SOURCE_TYPE_CATCH_PRODUCT_RECOMMENDER,
        pre_hydration_product_count: (metaData === null || metaData === void 0 ? void 0 : metaData.preHydrationCount) || null,
        post_hydration_product_count: (metaData === null || metaData === void 0 ? void 0 : metaData.postHydrationCount) || null,
        hydration_error_count: (metaData === null || metaData === void 0 ? void 0 : metaData.hydrationErrorCount) || null,
        page_limit: trackingVariables.current.limit,
        page_offset: trackingVariables.current.offset
      }, fromProduct));
    }
  }),
      data = _useSellerRecommended.data,
      error = _useSellerRecommended.error,
      loading = _useSellerRecommended.loading,
      fetchMore = _useSellerRecommended.fetchMore;

  var isMobile = useMedia(["(max-width: ".concat(minusOnePx(theme.breakpoints.md, true), ")")], [true]);
  useEventBus(TRIGGER_ONEPASS_FREE_SHIPPING_DRAWER_EVENT, function (event) {
    if (event.payload) {
      var _event$payload = event.payload,
          productId = _event$payload.productId,
          sellerId = _event$payload.sellerId,
          cartItemProductIds = _event$payload.cartItemProductIds,
          isAdult = _event$payload.isAdult,
          offerId = _event$payload.offerId,
          price = _event$payload.price;
      setEventPayload({
        productId: String(productId),
        excludedProductIds: cartItemProductIds,
        sellerId: sellerId,
        includeAdultCategory: !!isAdult,
        offerId: Number(offerId),
        price: Number(price)
      });
      setIsOpen(true);
    }
  });

  if (!(data !== null && data !== void 0 && data.sellerRecommendedProducts.products.length) || error) {
    return null;
  }

  var recommendedProducts = data.sellerRecommendedProducts.products;
  var _data$sellerRecommend = data.sellerRecommendedProducts.pagination,
      totalCount = _data$sellerRecommend.totalCount,
      nextItemIndex = _data$sellerRecommend.nextItemIndex;
  var productTiles = getProductTiles({
    products: recommendedProducts,
    sourceId: ONE_PASS_POPUP_RECOMMENDER_SOURCE_ID,
    sourceType: SOURCE_TYPE_CATCH_PRODUCT_RECOMMENDER,
    fromProduct: fromProduct
  });
  return ___EmotionJSX(FreeShippingModal, {
    isOpen: isOpen,
    contentProps: {
      paddingX: 0,
      paddingY: 0,
      background: theme.colors.grey
    },
    onRequestClose: function onRequestClose() {
      return setIsOpen(false);
    }
  }, ___EmotionJSX(ModalContent, null, ___EmotionJSX(BaseRecommenderCarousel, {
    trackingData: getRecommenderTrackingData(recommendedProducts, "product"),
    sourceType: SOURCE_TYPE_CATCH_PRODUCT_RECOMMENDER,
    fromProduct: fromProduct,
    sourceId: ONE_PASS_POPUP_RECOMMENDER_SOURCE_ID,
    maxTiles: totalCount,
    paginate: true,
    carouselProps: {
      showNavButtons: !isMobile,
      buttonOffset: [0, 0],
      containerProps: {
        width: "100%"
      },
      slidesPerBreakpoint: [4, 3, 2, 2, 2],
      showBullets: !!isMobile,
      onSlideChange: function onSlideChange(_ref2) {
        var currentIndex = _ref2.currentIndex,
            slidesPerView = _ref2.slidesPerView,
            direction = _ref2.direction;
        var lastVisibleTile = currentIndex + slidesPerView - 1;

        if (direction === "forward" && !recommendedProducts[lastVisibleTile]) {
          trackingVariables.current = {
            offset: nextItemIndex,
            limit: slidesPerView
          };
          fetchMore({
            variables: {
              offset: nextItemIndex
            },
            updateQuery: function updateQuery(prevResult, _ref3) {
              var fetchMoreResult = _ref3.fetchMoreResult;
              return handleRecommenderQueryUpdate({
                prevResult: prevResult,
                fetchMoreResult: fetchMoreResult,
                recommenderKey: "sellerRecommendedProducts"
              });
            }
          });
        }
      },
      disableNavButtons: {
        next: loading
      },
      touchEnabled: !loading
    },
    tiles: productTiles
  })));
};