import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useRef } from "react";
import { emitRecommenderRequest, SOURCE_TYPE_SHOP_PAGE_RECOMMENDER_TRENDING_PRODUCTS } from "@catchoftheday/analytics";
import { useTrendingProductsQuery } from "@personalisation/generated/graphql";
import { BaseRecommenderCarousel } from "../RecommenderCarousel";
import { getProductTiles, getRecommenderTrackingData, handleRecommenderQueryUpdate, useVisibleRecommenderTiles } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var TrendingProducts = function TrendingProducts(_ref) {
  var _ref$fromProduct = _ref.fromProduct,
      fromProduct = _ref$fromProduct === void 0 ? {
    from_product_ids: null,
    from_product_offer_id: null,
    from_product_displayed_price: null
  } : _ref$fromProduct,
      _ref$minCount = _ref.minCount,
      minCount = _ref$minCount === void 0 ? 0 : _ref$minCount,
      category = _ref.category,
      _ref$sourceType = _ref.sourceType,
      sourceType = _ref$sourceType === void 0 ? SOURCE_TYPE_SHOP_PAGE_RECOMMENDER_TRENDING_PRODUCTS : _ref$sourceType,
      _ref$Wrapper = _ref.Wrapper,
      Wrapper = _ref$Wrapper === void 0 ? React.Fragment : _ref$Wrapper;
  var visibleTiles = useVisibleRecommenderTiles();
  var trackingVariables = useRef({
    offset: 0,
    limit: visibleTiles
  });

  var _useTrendingProductsQ = useTrendingProductsQuery({
    ssr: false,
    variables: {
      category: category,
      limit: visibleTiles,
      offset: 0
    },
    onCompleted: function onCompleted(_ref2) {
      var _ref2$trendingProduct = _ref2.trendingProducts.metaData,
          preHydrationCount = _ref2$trendingProduct.preHydrationCount,
          postHydrationCount = _ref2$trendingProduct.postHydrationCount,
          hydrationErrorCount = _ref2$trendingProduct.hydrationErrorCount;
      emitRecommenderRequest(_objectSpread({
        source_type: sourceType,
        pre_hydration_product_count: preHydrationCount,
        post_hydration_product_count: postHydrationCount,
        hydration_error_count: hydrationErrorCount,
        page_offset: trackingVariables.current.offset,
        page_limit: trackingVariables.current.limit
      }, fromProduct));
    }
  }),
      data = _useTrendingProductsQ.data,
      loading = _useTrendingProductsQ.loading,
      error = _useTrendingProductsQ.error,
      fetchMore = _useTrendingProductsQ.fetchMore;

  if (!data || error) {
    return null;
  }

  var _data$trendingProduct = data.trendingProducts,
      products = _data$trendingProduct.products,
      _data$trendingProduct2 = _data$trendingProduct.pagination,
      totalCount = _data$trendingProduct2.totalCount,
      nextItemIndex = _data$trendingProduct2.nextItemIndex;

  if (!(products !== null && products !== void 0 && products.length) || totalCount && totalCount < minCount) {
    return null;
  }

  var tiles = getProductTiles({
    products: products,
    sourceType: sourceType,
    fromProduct: fromProduct
  });
  var trackingData = getRecommenderTrackingData(products, "product");
  return ___EmotionJSX(Wrapper, null, ___EmotionJSX(BaseRecommenderCarousel, {
    maxTiles: totalCount,
    tiles: tiles,
    paginate: true,
    trackingData: trackingData,
    sourceType: sourceType,
    fromProduct: fromProduct,
    carouselProps: {
      buttonOffset: [0, 0],
      onSlideChange: function onSlideChange(_ref3) {
        var currentIndex = _ref3.currentIndex,
            slidesPerView = _ref3.slidesPerView,
            direction = _ref3.direction;
        var lastVisibleTile = currentIndex + slidesPerView - 1;

        if (direction === "forward" && !products[lastVisibleTile]) {
          trackingVariables.current = {
            offset: nextItemIndex,
            limit: slidesPerView
          };
          fetchMore({
            variables: {
              offset: nextItemIndex
            },
            updateQuery: function updateQuery(prevResult, _ref4) {
              var fetchMoreResult = _ref4.fetchMoreResult;
              return handleRecommenderQueryUpdate({
                prevResult: prevResult,
                fetchMoreResult: fetchMoreResult,
                recommenderKey: "trendingProducts"
              });
            }
          });
        }
      },
      disableNavButtons: {
        next: loading
      },
      touchEnabled: !loading
    }
  }));
};